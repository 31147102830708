/*!
 * Additional methods which are not contained in the original library
 * jQuery Validation Plugin
 */

$.validator.addClassRules('required', {
	required: true,
	normalizer: function (value) {
		return $.trim(value);
	},
	depends: function (element) {
		if (element.value) {
			element.value = element.value.replace(/(^[ \t]+)/g, '');
		}
	}
});

$.validator.addMethod("passwordValidation", function (value, element) {
	return this.optional(element) || /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,30}$/g.test(value);
}, "Password must contain between 8 and 30 characters (at least one number, lower and upper case letter).");

$.validator.addMethod("passwordCharsRepeatValidation", function (value, element) {
	return this.optional(element) || !(/(.)\1\1/g.test(value));
}, "Password may not contain more than 3 sequentially repeating characters.");

$.validator.addMethod("emailValidation", function (value, element) {
	return this.optional(element) || /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
}, "Please enter a valid email.");

$.validator.addMethod("phoneValidation", function (value, element) {
	return this.optional(element) || /^((?:(\(\+?[0-9 ]+\))|(\+\(?[0-9 ]+\))|(?:\+?[0-9]+ \([0-9 ]+\))|(\+?[0-9 ]*))(?:([0-9 ])|([ ]*\([0-9 ]\)))[0-9- ]*)(?:[0-9])$/i.test(value);
}, "Please enter a valid phone number.");

$.validator.addMethod("urlValidation", function (value, element) {
	return this.optional(element) || /^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,16}(:[0-9]{1,5})?(\/.*)?$/.test(value);
}, "Please enter a valid url.");

$.validator.addMethod("domainValidation", function (value, element) {
	return this.optional(element) || /^(?!.*--)[a-z0-9][a-z0-9-]{0,30}[a-z0-9]$/.test(value);
}, "Please enter a valid domain name.");

$.validator.addMethod("googleMapDomainValidation", function (value, element) {
	return this.optional(element) || /(^https?:\/\/(www\.|maps\.)?google(\.[a-z]+){1,2}\/maps.+)|(^https:\/\/goo.gl\/maps\/.+)/i.test(value);
}, "Please enter a valid Google map URL.");

$.validator.addMethod("noSpacesAllowed", function (value, element) {
	return this.optional(element) || value.indexOf(" ") < 0;
}, "No spaces allowed.");

$.validator.addMethod("isEventPublishDateBefore", function (value) {
	const compareVal = $('#end-event-datepicker').val();
	if (!compareVal) {
		return true;
	}

	return new Date(value) <= new Date(compareVal)
}, 'Publish date must be before end date.');

$.validator.addMethod("isEventEndDateAfter", function (value) {
	if (!value) {
		return true;
	}

	const compareVal = $('#event-publish-date').val();
	if (!compareVal) {
		return true;
	}

	return new Date(value) >= new Date(compareVal);
}, 'End date must be after publish date.');
