(function (exports) {

	exports.limitless = (function () {

		var limitless = {};

		limitless.init = function limitlessInit() {

			$('#notifications-dropdown .dropdown-menu').on('click', function (e) {
				const target = e.target;
				return target.tagName.toLowerCase() === 'a' && !target.classList.contains('prevent-default');
			});

			// ========================================
			//
			// Main navigation
			//
			// ========================================

			// Add 'active' class to parent list item in all levels
			$('.navigation').find('li a.active').parent().parents('li').addClass('active').find('ul').show();

			// Hide all nested lists
			$('.navigation').find('li').not('.active, .category-title').has('ul').children('ul').addClass('hidden-ul');

			// Highlight children links
			$('.navigation').find('li').has('ul').children('a').addClass('has-ul');

			// Hide all nested lists when other item is clicked
			$(document).on('click', ".navigation > li:not(:has(ul)):not('.disabled, .navigation-header')", function () {
				$('.navigation').find('li').has('ul').removeClass('active').find('ul').hide()
			});

			// Main navigation tooltips positioning
			// -------------------------
			$('.navigation-main > .navigation-header > i').tooltip({
				placement: 'right',
				container: 'body'
			});

			// Collapsible functionality
			// -------------------------

			// Main navigation
			// Click on nav item with sub items
			$('.navigation-main').find('li').has('ul').children('a').off('click').on('click', function () {
				// Collapsible
				$(this).parent('li').not('.disabled').not($('.sidebar-xs').not('.sidebar-xs-indicator').find('.navigation-main').children('li')).toggleClass('active').children('ul').slideToggle(250);

				// Accordion
				if ($('.navigation-main').hasClass('navigation-accordion')) {
					$(this).parent('li').not('.disabled').not($('.sidebar-xs').not('.sidebar-xs-indicator').find('.navigation-main').children('li')).siblings(':has(.has-ul)').removeClass('active').children('ul').slideUp(250);
				}
			});

			// Toggle mini sidebar
			$('.sidebar-main-toggle').on('click', function () {
				$('body').toggleClass('sidebar-xs');
			});
			// Toggle main sidebar
			$('.sidebar-mobile-main-toggle').on('click', function () {
				$('body').toggleClass('sidebar-mobile-main').removeClass('sidebar-mobile-secondary sidebar-mobile-opposite sidebar-mobile-detached');
				$('html, body').animate({scrollTop: 0}, '200');
			});

			// trigger click on the hamburger when navigating to another view so the navigation hides in mobile view
			$(document).on('click', "body.sidebar-xs-indicator .navigation li:not(:has(ul)):not('.disabled, .navigation-header')", function () {
				$('.sidebar-mobile-main-toggle').click();
			});

			$(window).on('resize', function () {
				setTimeout(function () {
					if ($(window).width() <= 768) {
						$('body').addClass('sidebar-xs-indicator');
					} else {
						$('body').removeClass('sidebar-xs-indicator sidebar-mobile-main sidebar-mobile-secondary sidebar-mobile-detached sidebar-mobile-opposite');
					}
				}, 250);
			}).resize();

			// Plugins
			// -------------------------

			// Popover
			$('[data-popup="popover"]').popover();

			//automatically close popovers when clicked outside of them
			$(document).on('click', function (e) {
				$('[data-type="popover"],[data-original-title]').each(function () {
					//the 'is' for buttons that trigger popups
					//the 'has' for icons within a button that triggers a popup
					if ((!$(this).is(e.target)) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
						(($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false;  // fix for BS 3.3.6
					}
				});
			});

			$(document).on('click', '.tooltip-button-ok', function () {
				$(this).closest('.popover').popover('hide');
			});

			// Tooltip
			$('[data-popup="tooltip"]').tooltip();

			// LegitRipple
			$('.ripple').ripple();
		};

		return limitless;
	})();
})(window);
