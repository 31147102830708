var siteId;
var rawFile = new XMLHttpRequest();
rawFile.open("GET", 'assets/config/application.json', false);
rawFile.onreadystatechange = function () {
	if (rawFile.readyState === 4) {
		if (rawFile.status === 200 || rawFile.status === 0) {
			siteId = JSON.parse(rawFile.responseText).piwikID;
		}
	}
};
rawFile.send(null);

var _paq = _paq || [];
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
var url = location.href;
var domain = url.substr(url.indexOf('//') + 2, url.indexOf('.') - (url.indexOf('//') + 2));
_paq.push(['setCustomVariable', 1, 'organization', domain, 'visit']);
_paq.push(['trackPageView']);
(function () {
	_paq.push(['setTrackerUrl', window.location.origin + '/stats']);
	_paq.push(['setSiteId', siteId]);
	var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
	g.type = 'text/javascript';
	g.async = true;
	g.defer = true;
	g.src = 'https://piwik.helloteam.com/piwik.js';
	s.parentNode.insertBefore(g, s);
})();
