$.validator.setDefaults({
	onkeyup: function () {
		var originalKeyUp = $.validator.defaults.onkeyup;
		var customKeyUp = function (element, event) {
			var isPasswordField = element.type === 'password';

			if (isPasswordField) {
				return originalKeyUp.call(this, element, event);
			}

			return false;
		};

		return customKeyUp;
	}()
});

$.fn.modal.Constructor.DEFAULTS.backdrop = 'static';

$.fn.combodate.defaults.minYear = 1900;
$.fn.combodate.defaults.smartDays = true;

// ========================================
//
// Datetimepicker defaults
//
// ========================================
$.extend($.fn.datetimepicker.defaults, {
	useCurrent: false,
	format: 'YYYY-MM-DD',
	showTodayButton: true,
	showClear: true
});

// ========================================
//
// Bootstrap modals shown event
//
// ========================================
$(document).on('shown.bs.modal', '.modal', function () {
	var $modalContainer = $(this);

	if ($modalContainer.data('draggable')) {
		var $modalDialog = $modalContainer.find('.modal-dialog');

		$modalDialog.css({
			margin: '30px 13px 30px 30px',
			position: 'absolute',
			left: ($modalContainer.width() / 2 - $modalDialog.width() / 2) - 30
		});
	}
});

// ========================================
//
// Bootstrap modals hide and hidden events
//
// ========================================
$(document).on('hide.bs.modal', '.modal', function () {
	$('body').css('padding-right', '0');
});

$(document).on('hidden.bs.modal', '.modal', function () {
	$('body').css('padding-right', '0');

	var $this = $(this);

	if ($this.data('draggable')) {
		var $modalDialog = $this.find('.modal-dialog');

		$modalDialog.css({
			margin: '30px auto',
			position: 'relative',
			left: 0,
			top: 0
		});
	}

	// Remove data from standard inputs
	$this.removeData('bs.modal');

	// Clear datetimepickers
	var dateInputs = $(this).find('.date-input');
	if (dateInputs.length) {
		dateInputs.each(function () {
			var $this = $(this);
			$this.val('');
			$this.parent().data("DateTimePicker").destroy();
			$this.unbind();
		});
	}

	// Clear select2
	var searchableSelects = $this.find('select.searchable-select');
	if (searchableSelects.length) {
		searchableSelects.val('');
		searchableSelects.select2('destroy');
	}

	// Clear validation
	$this.find('input').removeClass('label-error');
	$this.find('select').removeClass('label-error');
	$this.find('textarea').removeClass('label-error');

	$this.find('label.label-error').remove();
});

// ========================================
//
// Fileinput defaults
//
// ========================================
$.extend($.fn.fileinput.defaults, {
	uploadAsync: true,

	fileActionSettings: {
		removeIcon: '<i class="icon-bin"></i>',
		removeClass: 'hide btn btn-link btn-xs btn-icon',
		uploadIcon: '<i class="icon-upload"></i>',
		uploadClass: 'hide btn btn-link btn-xs btn-icon',
		indicatorNew: '',
		indicatorSuccess: '<i class="icon-checkmark3 file-icon-large text-success"></i>',
		indicatorError: '<i class="icon-cross2 text-danger"></i>',
		indicatorLoading: '<i class="icon-spinner2 spinner text-muted"></i>'
	},

	uploadClass: 'btn btn-primary',
	browseClass: 'btn btn-primary btn-upload-file'
});

// ========================================
//
// Browser "back" button event ( remove opened modals )
//
// ========================================
$(window).on('popstate', function () {
	$('.modal').modal('hide');
	$(".modal-backdrop").remove();
});
